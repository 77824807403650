import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
} from "@mui/material";
import styled from "styled-components";
import { putCommaInvalue, totalprice } from "../../utils/Commonfunction";
import logoName from "../../assets/LogoName.png";
import { Check } from "@mui/icons-material";
import CustomerDetails from "./components/CustomerDetails";
import ArrivalWindowPicker from "./components/CalendarComponent";
import ResponsiveModal from "./components/ResponsiveModal";
import { useDispatch } from "react-redux";
import {
  getonlineFormData,
  submitonlineFormData,
} from "../../store/BookingForm/bookingFormReducer";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FiMinus, FiPlus } from "react-icons/fi";

export default function CustomerBookingForm() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState(data?.fields || []);
  const [selectedDateNTime, setSelectedDateNTime] = useState({});
  const dispatch = useDispatch();
  const [editValue, setEditValue] = useState({
    name: "",
    businessName: "",
    email: "",
  });

  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    address: "",
  });

  const editContact = () => {
    console.log("Saving contact:", editValue, address);
  };

  const totalSteps = formData.length + 2;

  const handleSelect = (option, isMultiple) => {
    setAnswers((prev) => {
      const fieldId = formData[step].label;
      const fieldType = isMultiple ? "multi_select" : "single_select";

      if (isMultiple) {
        const existing = prev[fieldId]?.value || [];
        return {
          ...prev,
          [fieldId]: {
            fieldId,
            type: fieldType,
            value: existing.some((itm) => itm?._id === option?._id)
              ? existing.filter((item) => item._id !== option._id)
              : [...existing, option],
          },
        };
      } else {
        return {
          ...prev,
          [fieldId]: {
            fieldId,
            type: fieldType,
            value: option,
          },
        };
      }
    });

    console.log(answers, "Updated Answers");

    if (!isMultiple && step < totalSteps - 1) {
      // setStep(step + 1);
    }
  };

  const handleQuantityChange = (amount, option, isMultiple) => {
    setAnswers((prev) => {
      const fieldId = formData[step].label;
      const selectedValue = prev[fieldId]?.value;

      if (isMultiple) {
        const updatedValues = selectedValue.map((item) =>
          item._id === option._id
            ? {
                ...item,
                quantity: Math.max((parseInt(item.quantity) || 1) + amount, 1),
              }
            : item
        );

        return {
          ...prev,
          [fieldId]: {
            fieldId,
            type: "multi_select",
            value: updatedValues,
          },
        };
      } else {
        if (!selectedValue || selectedValue.label !== option.label) return prev;
        const updatedValue = {
          ...selectedValue,
          quantity: Math.max(
            (parseInt(selectedValue.quantity) || 1) + amount,
            1
          ),
        };
        console.log(updatedValue, "cccc");

        return {
          ...prev,
          [fieldId]: {
            fieldId,
            type: "single_select",
            value: updatedValue,
          },
        };
      }
    });
  };

  const handleSubchangeChange = (value) => {
    setAnswers((prev) => ({
      ...prev,
      [formData[step].label]: {
        fieldId: formData[step].label,
        type: "quantity_select",
        value:value
      },
    }));
  };

  const init = async () => {
    setLoader(true);
    const res = await dispatch(getonlineFormData(id));
    setData(res?.payload?.data);
    setFormData(res?.payload?.data?.fields);
    setLoader(false);
    console.log(res, "csd");
  };
  useEffect(() => {
    init();
  }, []);
  console.log(answers, "sdcdxxxd");

  const extractFieldsToArray = (data) => {
    const result = [];
    for (const key in data) {
      const field = data[key];
      if (field?.fieldId && field?.type) {
        result.push({
          fieldId: field.fieldId,
          type: field.type,
          value: field.value,
        });
      }
    }

    return result;
  };

  const onSubmitForm = async () => {
    const value = {
      fields: extractFieldsToArray(answers),
      customerDetails: {
        ...editValue,
        ...address,
        to: ("" + editValue?.phone).replace(/\D/g, ""),
        name: `${editValue?.firstName} ${editValue?.lastName}`,
      },
      time: selectedDateNTime?.time,
      date: moment(selectedDateNTime?.date)?.format("DD MMM"),
    };
    console.log(value);
    const res = await dispatch(submitonlineFormData({ ...value, id: id }));
    if (res?.payload?.paymentUrl) {
      window.location.href = res?.payload?.paymentUrl;
    } else {
      navigate("/onlinebookingresponse");
    }
  };

  return (
    <ResponsiveModal isOpen={true} onClose={() => {}}>
      {loader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          {" "}
          <Spinner />
        </div>
      ) : (
        <Container className="max-w-lg mx-auto p-4">
          <Label style={{ margin: "20px 10px" }}>
            Step {step + 1} of {totalSteps}
          </Label>
          <LinearProgress
            variant="determinate"
            style={{ height: "6px", borderRadius: "10px" }}
            value={((step + 1) / totalSteps) * 100}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {step > 0 ? (
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
            ) : (
              <div />
            )}
            {step >= totalSteps - 2 ? null : (
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
                onClick={() => {
                  if (step < totalSteps - 1) {
                    setStep(step + 1);
                  }
                }}
              >
                {step === totalSteps - 1 ? "Finish" : "Next"}
              </Button>
            )}
          </div>
          <div style={{ maxHeight: "68vh", overflowY: "scroll" }}>
            {step < formData.length ? (
              <>
                <Header>{formData[step]?.label}</Header>

                {formData[step]?.type === "single_select" && (
                  <div>
                    {formData[step]?.options?.map((option) => (
                      <CardStyle
                        key={option._id}
                        onClick={() => handleSelect(option, false)}
                        className={
                          answers[formData[step].label]?.value?.label ===
                          option.label
                            ? "selected"
                            : ""
                        }
                      >
                        <RowContainer>
                          <RowHeader>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <RowName>{option.name}</RowName>
                              <RowDetails>
                                {option.description || "_"}
                              </RowDetails>
                            </div>
                            <p className="price">
                              $
                              {putCommaInvalue(
                                totalprice(
                                  option?.price,
                                  answers[formData[step].label]?.value
                                    ?.label === option.label
                                    ? answers[formData[step].label]?.value
                                        ?.quantity || 1
                                    : option?.quantity,
                                  option?.tax
                                )
                              )}
                            </p>
                          </RowHeader>
                        </RowContainer>
                        {answers[formData[step].label]?.value?.label ===
                          option.label && (
                          <QuantityContainer>
                            <div
                              style={{
                                display: "flex",
                                background: "#eee",
                                padding: "3px",
                                alignItems: "center",
                                borderRadius: "4px",
                              }}
                            >
                              <IconButton
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(-1, option);
                                }}
                              >
                                <FiMinus />
                              </IconButton>
                              <QuantityValue>
                                {answers[formData[step].label]?.value
                                  ?.quantity || 1}
                              </QuantityValue>
                              <IconButton
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(1, option);
                                }}
                              >
                                <FiPlus />
                              </IconButton>
                            </div>
                          </QuantityContainer>
                        )}
                      </CardStyle>
                    ))}
                  </div>
                )}

                {formData[step]?.type === "multi_select" && (
                  <div>
                    {formData[step]?.options?.map((option) => (
                      <CardStyle
                        key={option._id}
                        onClick={() => handleSelect(option, true)}
                        className={
                          answers[formData[step].label]?.value?.some(
                            (itm) => itm?._id === option?._id
                          )
                            ? "selected"
                            : ""
                        }
                      >
                        <RowContainer>
                          <RowHeader>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                {answers[formData[step].label]?.value?.some(
                                  (itm) => itm?._id === option?._id
                                ) && (
                                  <Check
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "5px",
                                    }}
                                  />
                                )}
                                <RowName>{option.name}</RowName>
                              </div>
                              <RowDetails>
                                {option.description || "_"}
                              </RowDetails>
                            </div>
                            <p className="price">
                              $
                              {putCommaInvalue(
                                totalprice(
                                  option?.price,
                                  answers[formData[step].label]?.value?.some(
                                    (itm) => itm?._id === option?._id
                                  )
                                    ? answers[
                                        formData[step].label
                                      ]?.value?.find(
                                        (itm) => itm?._id === option?._id
                                      )?.quantity || 1
                                    : option?.quantity,
                                  option?.quantity,
                                  option?.tax
                                )
                              )}
                            </p>
                          </RowHeader>
                        </RowContainer>
                        {answers[formData[step].label]?.value?.some(
                          (itm) => itm?._id === option?._id
                        ) && (
                          <QuantityContainer>
                            <div
                              style={{
                                display: "flex",
                                background: "#eee",
                                padding: "3px",
                                alignItems: "center",
                                borderRadius: "4px",
                              }}
                            >
                              <IconButton
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(-1, option, true);
                                }}
                              >
                                <FiMinus />
                              </IconButton>
                              <QuantityValue>
                                {answers[formData[step].label]?.value?.find(
                                  (itm) => itm?._id === option?._id
                                )?.quantity || 1}
                              </QuantityValue>
                              <IconButton
                                className="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuantityChange(1, option, true);
                                }}
                              >
                                <FiPlus />
                              </IconButton>
                            </div>
                          </QuantityContainer>
                        )}
                      </CardStyle>
                    ))}
                  </div>
                )}

                {formData[step]?.type === "quantity_select" && (
                  <InputText id="outlined-basic" size="small"
                  type={"date"}
                  placeholder={"Enter here"}
                  value={answers[formData[step].label]?.value}
                  onChange={(e) => {
                    handleSubchangeChange(e.target.value)
                  }}
                  variant="outlined" />
                )}
              </>
            ) : step === formData?.length ? (
              <CustomerDetails
                editValue={editValue}
                setEditValue={setEditValue}
                editContact={editContact}
                setEdit={() => setStep(step + 1)}
                address={address}
                setAddress={setAddress}
              />
            ) : (
              <ArrivalWindowPicker
                selectedDatevalue={(val) => {
                  setSelectedDateNTime({ ...selectedDateNTime, date: val });
                }}
                selectetedTime={(val) => {
                  setSelectedDateNTime({ ...selectedDateNTime, time: val });
                }}
                days={data?.settings?.workingHours}
                onClick={() => {
                  onSubmitForm();
                }}
              />
            )}
          </div>
          <FooterContainer>
            Powered by
            <Logo style={{ marginLeft: "10px" }} src={logoName} />
          </FooterContainer>
        </Container>
      )}
    </ResponsiveModal>
  );
}

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
 &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media (max-width: 768px) {
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 30px;
  }
`;

const CardStyle = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 20px 10px;
&::-webkit-scrollbar {
  width: 0;
  height: 0;
}
  &:hover,
  &.selected {
    background-color: #f0f8ff;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border-top: 1px solid #eee;
  transition: background-color 0.2s ease;
  cursor: pointer;
  position: relative;
`;

const RowHeader = styled.div`
  display: flex;
  align-items: center;
 justify-content:space-between;
 p {
    font-size: 13px;
    &.name {
      font-weight: 550;
      text-align: start;
      margin-top:0px;
      margin-bottom:5px;
  
    }
`;

const RowName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: start;
`;

const RowDetails = styled.span`
  font-size: 13px;
  color: #757575;
  text-align: start;
  margin-top: 4px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  text-align: start;
`;

const Header = styled.h2`
  font-weight: 600;
  text-align: start;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  margin-bottom: 15px;
  margin-right: 10px;
  .button {
    border: none;
    border-radius: 4;
    cursor: pointer;
    padding: 1px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: bold;

    color: #333;
  }

  .quantity {
    background-color: white;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    flex: 1;
  }
`;

const QuantityValue = styled.span`
  font-size: 13px;
  font-weight: bold;
  min-width: 40px;

  text-align: center;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px 0;
  font-size: 13px;
`;

const Logo = styled.img`
  width: 60px;
  margin-left: 15px;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #0f52ba;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  margin: 20px auto;
`;
const InputText = styled.textarea`
font-family:Inter;
width: 90%;
border-radius: 5px;
font-size: 14px;
min-height: 95px;
font-weight: 400;
color: #344054;
background: #ffffff;
border: 0.5px solid #d0d5dd;

padding: 10px 14px;
  resize: vertical;
  ::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
  @media (min-width: 260px) and (max-width: 721px) {
    width: 100%;
  }
`;
